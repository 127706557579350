"use client";
import {
    Search,
    SearchPayload,
    SearchRemovePayload,
} from "@/redux/types/search/SearchTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: Search = {
    searchCourses: "",
    listCourses: "",
    listRatings: "",
    listVideos: "",
    listCategories: "",
    listSubcategories: "",
    listUsers: "",
    listCoupons: "",
    listOrders: "",
};

const searchSlice = createSlice({
    name: "searchSlice",
    initialState,
    reducers: {
        setSearch: (state, action: PayloadAction<SearchPayload>) => {
            const { key, value } = action.payload;
            state[key || ""] = value;
        },
        removeSearch: (state, action: PayloadAction<SearchRemovePayload>) => {
            const { key } = action.payload;
            state[key || ""] = "";
        },
    },
});

const searchReducer = searchSlice.reducer;

export const { setSearch, removeSearch } = searchSlice.actions;
export default searchReducer;
