"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { removeItems, uniqueAddItems } from "@/utils/arrays/Items";
import {
    CheckList,
    CheckPayload,
    CheckRemoveAllPayload,
} from "@/redux/types/checkList/checkListTypes";

const initialState: CheckList = {
    checkCourses: [],
    checkVideos: [],
    checkRatings: [],
    checkCategories: [],
    checkSubcategories: [],
    checkUsers: [],
    checkOrders: [],
    checkCoupons: [],
};

const checkListSlice = createSlice({
    name: "checkListSlice",
    initialState,
    reducers: {
        addIdChecked: (state, action: PayloadAction<CheckPayload>) => {
            state[`${action.payload.key}`] = uniqueAddItems(
                state[`${action.payload.key}`],
                action.payload.value
            );
        },
        removeIdChecked: (state, action: PayloadAction<CheckPayload>) => {
            state[`${action.payload.key}`] = removeItems(
                state[`${action.payload.key}`],
                action.payload.value
            );
        },
        removeAllChecked: (state, action: PayloadAction<CheckRemoveAllPayload>) => {
            state[`${action.payload.key}`] = [];
        },
    },
});

const checkListReducer = checkListSlice.reducer;

export const { addIdChecked, removeIdChecked, removeAllChecked } =
    checkListSlice.actions;
export default checkListReducer;
