"use client";
import Image from "next/image";
import Link from "next/link";
import ThemeSwitcher from "../common/themeSwitcher/ThemeSwitcher";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaShoppingCart } from "react-icons/fa";
import useHeader from "@/hooks/header/useHeader";
import avatarUserImg from "@/../public/images/avatur-user2.png";
import SolidLinkClient from "@/components/serverToClient/SolidLinkClient";
import logoLightPure from "@/../public/logo/logoLightPure.png";
import logoDarkPure from "@/../public/logo/logoDarkPure.png";
import Search from "../common/search/Search";
import isNotStudent from "@/utils/auth/roles/isNotStudent";
import getFromStorage from "@/utils/localStorage/getFromStorage";
import ownerOnly from "@/utils/auth/roles/ownerOnly";

function Header() {
    const {
        linksActive,
        userActive,
        links,
        pathName,
        handleClickMenu,
        handleUserMenu,
        handleClickLogo,
        handleClickDisAppearMenu,
        userLoggedName,
        userLoggedImage,
        role,
        accessToken,
        handleLogout,
        handleClickDisUserMenu,
        pageLoaded
    } = useHeader();

    return (
        <header>
            <div className="container">
                <span className={"logo-text"}>
                    <div className="logo-image-hand">
                        <Image src={logoLightPure} alt="logo" className="logoLightPure" width={100} height={100} onClick={handleClickLogo} />
                        <Image src={logoDarkPure} alt="logo" className="logoDarkPure" width={100} height={100} onClick={handleClickLogo} />
                    </div>
                    <span className="font-vip elianto"><span className="aqua-color elianto">Physics </span>Nerds</span>
                    <Search keySearch={"searchCourses"} redirect={"/courses"} defaultValue={getFromStorage("searchCourses")} />
                </span>
                <ul role="navigation"
                    className={`link-list ${linksActive} ${
                        accessToken && role === "student" ? "student" : "" 
                        }`}
                >
                    {Array.isArray(links) && links.map((el) => (
                        <li
                            key={el.id}
                            className={el.path === pathName ? "active" : ""}
                        >
                            <Link prefetch={false}
                                href={el.path}
                                onClick={handleClickDisAppearMenu}
                            >
                                {el.name}
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className="hand-theme-icon">
                    <ThemeSwitcher size={30} name={"theme-icon"} />
                </div>
                {!isNotStudent(role) &&
                    <a href={"/user/cart"} className={`link-shop ${!accessToken ? "hide" : ""}`}>
                        <FaShoppingCart size={25} className={"icon-shop"} />
                    </a>
                }
                {pageLoaded &&
                    <div
                        className={`btn-group-header ${accessToken ? "hide" : ""}`}
                    >
                        <Link prefetch={false} href="/login" className="login" onClick={handleClickDisAppearMenu}>
                            تسجيل الدخول
                        </Link>
                        <SolidLinkClient
                            text={"سجل معنا"}
                            link={"/register"}
                            newClass={"header-join"}
                            onHandleClick={handleClickDisAppearMenu}
                        />
                    </div>
                }
                <div className={`user-menu ${!accessToken ? "hide" : ""}`} onClick={handleUserMenu}>
                    <Image
                        className={"user-avatar"}
                        src={
                            userLoggedImage || avatarUserImg
                        }
                        alt="الصورة الشخصية"
                        width={100}
                        height={100}
                    />
                    <ul
                        className={`logged-list ${userActive} ${accessToken ? "logged-in" : ""
                            }`}
                    >
                        <li>
                            <p className="user-logged-name">{userLoggedName}</p>
                        </li>
                        <li>
                            <a href={"/user/profile"} onClick={handleClickDisUserMenu}>
                                الصفحة الشخصية
                            </a>
                        </li>
                        <li>
                            {isNotStudent(role) &&
                                <a href={ownerOnly() ? "/admin/summary" : "/admin/users"} onClick={handleClickDisUserMenu}>
                                    لوحة التحكم
                                </a>
                            }
                        </li>
                        <li>
                            <button
                                onClick={() => {
                                    handleClickDisUserMenu(), handleLogout();
                                }}
                                className="solid-common-btn"
                            >
                                تسجيل الخروج
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="menu-icon-hand">
                    <RxHamburgerMenu
                        size={30}
                        onClick={handleClickMenu}
                        className="menu-icon"
                    />
                </div>
            </div>
        </header>
    );
}

export default Header;
