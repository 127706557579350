"use client";
import useThemeSwitcher from "@/hooks/common/useThemeSwitcher";
import { BsSun } from "react-icons/bs";
import { FiMoon } from "react-icons/fi";
type Props = {
    size: number;
    name: string;
};

function ThemeSwitcher({ size, name }: Props) {
    const theme = useThemeSwitcher();

    return (
        <>
            {theme?.themeUI === "lightIcon" && <BsSun
                size={size}
                cursor="pointer"
                className={name}
                onClick={() => theme?.setTheme("light")}
            />}
            {theme?.themeUI === "darkIcon" && <FiMoon
                size={size}
                cursor="pointer"
                className={name}
                onClick={() => theme?.setTheme("dark")}
            />}
        </>
    );

}

export default ThemeSwitcher;
