import roles from "./roles";

const isNotStudent = (role: string | undefined) => {
    return (
        role === roles.OWNER ||
        role === roles.ADMIN ||
        role === roles.VIEWER ||
        role === "owner" ||
        role === "admin" ||
        role === "viewer"
    );
};
export default isNotStudent;
