"use client";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useIsOnline } from "react-use-is-online";

type Props = {
    children: React.ReactNode;
};

function HotToastProv({ children }: Props) {
    const { isOnline, isOffline } = useIsOnline();
    const [toastId, setToastId] = useState("");
    useEffect(() => {
        if (isOffline) {
            const detectToastId = toast.loading("غير متصل بالإنترنت - الرجاء التحقق من اتصالك بالإنترنت", {
                style: { minWidth: 'fit-content' },
            });
            setToastId(detectToastId);
        }
        if (isOnline && toastId) {
            toast.dismiss(toastId);
        }
    }, [isOnline, isOffline]);
    
    return (
        <div>
            <div>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
            </div>
            {children}
        </div>
    );
}

export default HotToastProv;
