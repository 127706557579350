import {
    BaseQueryApi,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { setCredentials, logout } from "@/redux/features/local/auth/authSlice";
import { RootState } from "../store";
import baseUrl from "@/api/baseUrl";
import { RefreshResult } from "@/redux/types/auth/AuthTypes";

export const baseQuery = fetchBaseQuery({
    baseUrl,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }
    },
});

export const baseQueryWithReAuth = async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: {}
) => {
    const baseQueryResult = await baseQuery(args, api, extraOptions);
    if ((baseQueryResult?.error as FetchBaseQueryError)?.status === 401) {
        const refreshResult = (await baseQuery(
            "/api/v1/auth/refresh",
            api,
            extraOptions
        )) as RefreshResult;
        if (refreshResult.data) {
            const email = (api.getState() as RootState).auth.email;
            api.dispatch(
                setCredentials({
                    email: email as string,
                    accessToken: refreshResult.data.data.accessToken as string,
                    user: refreshResult.data.data.user as any,
                })
            );
            return await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }

    return baseQueryResult;
};

//-------------------------------------------------------------- Create an API instance with baseQuery and baseQueryWithReAuth--------------------------------------------------------------------------------------
export const apiAuthSlice = createApi({
    reducerPath: "authApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Auth"],
});

export const apiNoTokenAuthSlice = createApi({
    reducerPath: "authNoTokenApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({}),
    tagTypes: ["AuthNoToken"],
});

export const apiCategoriesSlice = createApi({
    reducerPath: "categoriesApi",
    baseQuery: baseQueryWithReAuth,
    refetchOnFocus: true,
    endpoints: (builder) => ({}),
    tagTypes: ["Categories"],
});

export const apiSubCategoriesSlice = createApi({
    reducerPath: "subCategoriesApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["SubCategories"],
});

export const apiCoursesSlice = createApi({
    reducerPath: "coursesApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Courses"],
});

export const apiChaptersSlice = createApi({
    reducerPath: "chaptersApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Chapters"],
});

export const apiVideosSlice = createApi({
    reducerPath: "videosApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Videos"],
});

export const apiUsersSlice = createApi({
    reducerPath: "usersApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Users"],
});

export const apiLoggedUserSlice = createApi({
    reducerPath: "loggedUserApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["LoggedUser"],
});

export const apiReviewsSlice = createApi({
    reducerPath: "reviewsApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Reviews"],
});

export const apiCartSlice = createApi({
    reducerPath: "cartApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Cart"],
});

export const apiCouponsSlice = createApi({
    reducerPath: "couponsApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Coupons"],
});

export const apiOrdersSlice = createApi({
    reducerPath: "ordersApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Orders"],
});

export const apiPurchaseSlice = createApi({
    reducerPath: "purchaseApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Purchase"],
});

export const apiStatisticsSlice = createApi({
    reducerPath: "summaryApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["Statistics"],
});

export const apiOurVisionSlice = createApi({
    reducerPath: "ourVisionApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({}),
    tagTypes: ["OurVision"],
});
