"use client";
import {
    AddVideoPayload,
    EditAllVideosPayload,
    EditVideosPayload,
    EditVideosState,
    removeVideoPayload,
    removeVideosPayload,
} from "@/redux/types/edits/EditVideosTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: EditVideosState = {};

const editVideosSlice = createSlice({
    name: "editVideosSlice",
    initialState,
    reducers: {
        updateVideosAllFields: (
            state,
            action: PayloadAction<EditAllVideosPayload>
        ) => {
            const { id, value } = action.payload;
            state[`${id}`] = value;
        },
        updateVideosOneField: (
            state,
            action: PayloadAction<EditVideosPayload>
        ) => {
            const { id, key } = action.payload;
            const newState = state[`${id}`]["videos"].map((el) =>
                el.id === key.id ? key : el
            );
            state[`${id}`]["videos"] = newState;
        },
        addVideo: (state, action: PayloadAction<AddVideoPayload>) => {
            const { id, value } = action.payload;
            state[`${id}`]?.videos?.push(value as never);
        },
        removeVideo: (state, action: PayloadAction<removeVideoPayload>) => {
            const { id, value } = action.payload;
            const newState = state[`${id}`]["videos"].filter(
                (el) => el.id !== value.id
            );
            state[`${id}`]["videos"] = newState;
        },
        removeVideos: (state, action: PayloadAction<removeVideosPayload>) => {
            const { id, value } = action.payload;
            let newState;
            for (let elOne of value) {
                newState = state[`${id}`]["videos"].filter(
                    (el) => el.id !== elOne.id
                );
            }
            if (newState) {
                state[`${id}`]["videos"] = newState;
            }
        },
    },
});

const EditVideosSliceReducer = editVideosSlice.reducer;

export const { updateVideosAllFields, updateVideosOneField, addVideo, removeVideo, removeVideos } =
    editVideosSlice.actions;
export default EditVideosSliceReducer;
