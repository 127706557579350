"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    AddChapterPayload,
    EditAllCoursePayload,
    EditCoursePayload,
    EditCourseState,
} from "@/redux/types/edits/EditCourseTypes";

const initialState: EditCourseState = {};

const editCoursesSlice = createSlice({
    name: "editCoursesSlice",
    initialState,
    reducers: {
        updateCourseAllFields: (
            state,
            action: PayloadAction<EditAllCoursePayload>
        ) => {
            const { id, value } = action.payload;
            state[`${id}`] = value;
        },
        updateCourseOneField: (
            state,
            action: PayloadAction<EditCoursePayload>
        ) => {
            const { id, key, value } = action.payload;
            state[`${id}`][`${key}`] = value;
        },
        addChapter: (state, action: PayloadAction<AddChapterPayload>) => {
            const { id, value } = action.payload;
            state[`${id}`]?.chapter?.push(value as never);
        },
    },
});

const editCourseReducer = editCoursesSlice.reducer;

export const { updateCourseAllFields, updateCourseOneField, addChapter } =
    editCoursesSlice.actions;
export default editCourseReducer;