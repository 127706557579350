"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ChapterTitle = {
    chapterTitle: string | undefined;
};

const initialState: ChapterTitle = {
    chapterTitle: "",
};

const chapterTitleSlice = createSlice({
    name: "chapterTitleSlice",
    initialState,
    reducers: {
        addChapterTitle: (state, action: PayloadAction<{ title: string }>) => {
            state.chapterTitle = action.payload.title;
        },
    },
});

const chapterTitleReducer = chapterTitleSlice.reducer;

export const { addChapterTitle } = chapterTitleSlice.actions;
export default chapterTitleReducer;
