"use client";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";

interface ThemeSwitcherProps {
  themeUI: string | undefined;
  setTheme: (theme: string) => void;
}

function useThemeSwitcher(): ThemeSwitcherProps | null {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme, systemTheme } = useTheme();
  const [themeUI, setThemeUI] = useState<string>();

  useEffect(() => {
    setMounted(true);
    if ((theme === "system" && systemTheme === "dark") || theme === "dark") setThemeUI("lightIcon");
    if ((theme === "system" && systemTheme === "light") || theme === "light") setThemeUI("darkIcon");
  }, [theme, systemTheme]);

  if (!mounted) {
    return null;
  }

  return { themeUI, setTheme };
}

export default useThemeSwitcher;
