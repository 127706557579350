"use client";
import HotToastProv from "./HotToastProv";
import PayPalScriptProv from "./PayPalProv";
import ReduxProv from "./ReduxProv";
import ThemeProv from "./ThemeProv";

type Props = {
    children: React.ReactNode;
};

function Providers({ children }: Props) {
    return (
        <PayPalScriptProv>
            <ReduxProv>
                <ThemeProv>
                    <HotToastProv>
                        {children}
                    </HotToastProv>
                </ThemeProv>
            </ReduxProv>
        </PayPalScriptProv>
    );
}

export default Providers;
