"use client";
import { ThemeProvider } from "next-themes";

type Props = {
    children: React.ReactNode;
};

function ThemeProv({ children }: Props) {
    return <ThemeProvider attribute="class">{children}</ThemeProvider>;
}

export default ThemeProv;
