"use client";
import {
    EditAllChaptersPayload,
    EditChaptersPayload,
    EditChaptersState,
} from "@/redux/types/edits/EditChapterTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: EditChaptersState = {};

const editChaptersSlice = createSlice({
    name: "editChaptersSlice",
    initialState,
    reducers: {
        updateChapterAllFields: (
            state,
            action: PayloadAction<EditAllChaptersPayload>
        ) => {
            const { id, value } = action.payload;
            state[`${id}`] = value;
        },
        updateChapterOneField: (
            state,
            action: PayloadAction<EditChaptersPayload>
        ) => {
            const { id, key, value } = action.payload;
            state[`${id}`][`${key}`] = value;
        },
    },
});

const EditChapterReducer = editChaptersSlice.reducer;

export const { updateChapterAllFields, updateChapterOneField } =
    editChaptersSlice.actions;
export default EditChapterReducer;
