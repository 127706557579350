"use client";
// import { PayPalScriptProvider } from "@paypal/react-paypal-js";

type Props = {
    children: React.ReactNode;
};
function PayPalScriptProv({ children }: Props) {
    // const initialOptions = {
    //     clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string,
    //     currency: "USD",
    //     intent: "capture",
    //     disableFunding: ['card', 'ideal', 'sofort']
    // };
    return (
        <>
            {/* <PayPalScriptProvider options={initialOptions}> */}
            {children}
            {/* </PayPalScriptProvider> */}
        </>
    );
}

export default PayPalScriptProv;