"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FristVideo = {
    fristVideoID: string | undefined;
};

const initialState: FristVideo = {
    fristVideoID: "",
};

const fristVideoIDSlice = createSlice({
    name: "fristVideoIDSlice",
    initialState,
    reducers: {
        addfristVideoID: (state, action: PayloadAction<string | undefined>) => {
            state.fristVideoID = action.payload;
        },
    },
});

const fristVideoIDReducer = fristVideoIDSlice.reducer;

export const { addfristVideoID } = fristVideoIDSlice.actions;
export default fristVideoIDReducer;
