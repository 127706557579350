"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    EditAllProfilePayload,
    EditProfilePayload,
    EditProfileState,
} from "@/redux/types/edits/EditProfileTypes";

const initialState: EditProfileState = {
    profile: null,
};

const editProfileSlice = createSlice({
    name: "editProfileSlice",
    initialState,
    reducers: {
        updateProfileAllFields: (
            state,
            action: PayloadAction<EditAllProfilePayload>
        ) => {
            const { value } = action.payload;
            state.profile = { ...value };
        },
        updateProfileOneField: (
            state,
            action: PayloadAction<EditProfilePayload>
        ) => {
            const { key, value } = action.payload;
            if (state?.profile) {
                state[`profile`][`${key}`] = value;
            }
        },
    },
});

const EditProfileReducer = editProfileSlice.reducer;

export const { updateProfileAllFields, updateProfileOneField } =
    editProfileSlice.actions;
export default EditProfileReducer;
