"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    EditAllUserPayload,
    EditUserPayload,
    EditUserState,
} from "@/redux/types/edits/EditUserTypes";

const initialState: EditUserState = {};

const editUserSlice = createSlice({
    name: "editUserSlice",
    initialState,
    reducers: {
        updateUserAllFields: (
            state,
            action: PayloadAction<EditAllUserPayload>
        ) => {
            const { id, value } = action.payload;
            state[`${id}`] = value;
        },
        updateUserOneField: (state, action: PayloadAction<EditUserPayload>) => {
            const { id, key, value } = action.payload;
            state[`${id}`][`${key}`] = value;
        },
    },
});

const EditUserReducer = editUserSlice.reducer;

export const { updateUserAllFields, updateUserOneField } =
    editUserSlice.actions;
export default EditUserReducer;
