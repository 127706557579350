'use client'

import { useEffect } from "react";

const ClickPage = () => {

    useEffect(() => {
        const mainMenuEle = typeof document !== 'undefined' && document.querySelector('header ul.link-list');
        const loggedMenuEle = typeof document !== 'undefined' && document.querySelector('header .user-menu ul.logged-list');

        const mainElement = typeof document !== 'undefined' && document.querySelector('body');

        const handleClick = (event: MouseEvent) => {
            if (mainMenuEle && !mainMenuEle.contains(event.target as Node)) {
                mainMenuEle.classList.remove('active');
            }

            if (loggedMenuEle && !loggedMenuEle.contains(event.target as Node)) {
                loggedMenuEle?.classList.remove("active");
            }
        };
        if (mainElement && typeof document !== 'undefined') {
            mainElement.addEventListener('click', handleClick);
        }

        return () => {
            typeof document !== 'undefined' && document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <></>
    );
}

export default ClickPage;
