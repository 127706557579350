"use client";
import {
    EditAllChaptersCoursePayload,
    editChaptersCoursesPayload,
    editChaptersCoursesState,
} from "@/redux/types/edits/EditChaptersCourseTypes";
import { AddChapterPayload } from "@/redux/types/edits/EditCourseTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: editChaptersCoursesState = {};

const editChaptersCourseCourseSlice = createSlice({
    name: "editChaptersCourseCourseSlice",
    initialState,
    reducers: {
        updateChaptersCourseAllFields: (
            state,
            action: PayloadAction<EditAllChaptersCoursePayload>
        ) => {
            const { id, value } = action.payload;
            state[`${id}`] = value;
        },
        updateChapterCourseOneField: (
            state,
            action: PayloadAction<editChaptersCoursesPayload>
        ) => {
            const { id, key, value } = action.payload;
            state[`${id}`][`${key}`] = value;
        },
        addChapterCourse: (state, action: PayloadAction<AddChapterPayload>) => {
            const { id, value } = action.payload;
            state[`${id}`]?.chapters?.push(value as never);
        },
    },
});

const editChaptersCourseReducer = editChaptersCourseCourseSlice.reducer;

export const {
    updateChaptersCourseAllFields,
    updateChapterCourseOneField,
    addChapterCourse,
} = editChaptersCourseCourseSlice.actions;
export default editChaptersCourseReducer;
