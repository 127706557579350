"use client"
import { IoSearchSharp } from "react-icons/io5";
import { Formik, Form, Field, FormikProps } from "formik";
import useSearch from "@/hooks/common/search/useSearch";


type PropsSearch = {
    keySearch: any;
    redirect?: string;
    defaultValue?: any;
}
type FormSearchValues = {
    searchValue: ""
}
const Search = ({ keySearch, redirect, defaultValue }: PropsSearch) => {
    const {
        initialValues,
        validationSchema,
        handleSubmit,
        handleChange,
    } = useSearch(keySearch, redirect, defaultValue);


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formik: FormikProps<FormSearchValues>) => (
                <Form className="form-search">
                    <div className="form-middle form-middle-search">
                        <div className="field-box ">
                            <div className="field-wrapper search">
                                <IoSearchSharp className="icon-search" size={20} />
                                <Field
                                    role="text"
                                    type="text"
                                    id="searchValue"
                                    className="search-input"
                                    name="searchValue"
                                    aria-label="ابحث"
                                    placeholder="ابحث"
                                    autoComplete="off"
                                    onChange={(currentvalue: any) => {
                                        formik.setFieldValue('searchValue', currentvalue.target.value);
                                        handleChange(currentvalue.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </Form>
            )}
        </Formik>
    );
}

export default Search;