import { configureStore } from "@reduxjs/toolkit";
import {
    apiAuthSlice,
    apiNoTokenAuthSlice,
    apiCategoriesSlice,
    apiSubCategoriesSlice,
    apiCoursesSlice,
    apiChaptersSlice,
    apiVideosSlice,
    apiUsersSlice,
    apiReviewsSlice,
    apiCartSlice,
    apiCouponsSlice,
    apiOrdersSlice,
    apiLoggedUserSlice,
    apiPurchaseSlice,
    apiStatisticsSlice,
    apiOurVisionSlice,
} from "./api/apiSlice";
import authReducer from "../features/local/auth/authSlice";
import searchReducer from "../features/local/search/searchSlice";
import checkListReducer from "../features/local/checkList/checkListSlice";
import editCourseReducer from "../features/local/edits/editCourseSlice";
import EditChapterReducer from "../features/local/edits/editChapterSlice";
import EditProfileReducer from "../features/local/edits/editProfileSlice";
import EditCategoryReducer from "../features/local/edits/editCategorySlice";
import EditSubcategoryReducer from "../features/local/edits/editSubcategorySlice";
import editChaptersCourseReducer from "../features/local/edits/editChaptersCourseSlice";
import EditVideosSliceReducer from "../features/local/edits/editVideosSlice";
import EditUpdatePublish from "../features/local/edits/editPublishSlice";
import chapterTitleReducer from "../features/local/active/chapterTitleSlice";
import EditUserReducer from "../features/local/edits/editUserSlice";
import fristVideoIDReducer from "../features/local/active/fristVideoIDSlice";
import userCountryReducer from "../features/local/userCountry/userCountrySlice";

const store = configureStore({
    reducer: {
        [apiAuthSlice.reducerPath]: apiAuthSlice.reducer,
        [apiNoTokenAuthSlice.reducerPath]: apiNoTokenAuthSlice.reducer,
        [apiCategoriesSlice.reducerPath]: apiCategoriesSlice.reducer,
        [apiSubCategoriesSlice.reducerPath]: apiSubCategoriesSlice.reducer,
        [apiCoursesSlice.reducerPath]: apiCoursesSlice.reducer,
        [apiChaptersSlice.reducerPath]: apiChaptersSlice.reducer,
        [apiVideosSlice.reducerPath]: apiVideosSlice.reducer,
        [apiUsersSlice.reducerPath]: apiUsersSlice.reducer,
        [apiLoggedUserSlice.reducerPath]: apiLoggedUserSlice.reducer,
        [apiReviewsSlice.reducerPath]: apiReviewsSlice.reducer,
        [apiCartSlice.reducerPath]: apiCartSlice.reducer,
        [apiCouponsSlice.reducerPath]: apiCouponsSlice.reducer,
        [apiOrdersSlice.reducerPath]: apiOrdersSlice.reducer,
        [apiPurchaseSlice.reducerPath]: apiPurchaseSlice.reducer,
        [apiStatisticsSlice.reducerPath]: apiStatisticsSlice.reducer,
        [apiOurVisionSlice.reducerPath]: apiOurVisionSlice.reducer,
        auth: authReducer,
        search: searchReducer,
        checkList: checkListReducer,
        editCourses: editCourseReducer,
        editChaptersCourse: editChaptersCourseReducer,
        editChapters: EditChapterReducer,
        editVideos: EditVideosSliceReducer,
        editCategories: EditCategoryReducer,
        editSubcategories: EditSubcategoryReducer,
        editUsers: EditUserReducer,
        editProfile: EditProfileReducer,
        editUpdatePublish: EditUpdatePublish,
        chapterTitle: chapterTitleReducer,
        fristVideoID: fristVideoIDReducer,
        userCountry: userCountryReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            apiAuthSlice.middleware,
            apiNoTokenAuthSlice.middleware,
            apiCategoriesSlice.middleware,
            apiSubCategoriesSlice.middleware,
            apiCoursesSlice.middleware,
            apiChaptersSlice.middleware,
            apiVideosSlice.middleware,
            apiUsersSlice.middleware,
            apiLoggedUserSlice.middleware,
            apiReviewsSlice.middleware,
            apiCartSlice.middleware,
            apiCouponsSlice.middleware,
            apiOrdersSlice.middleware,
            apiPurchaseSlice.middleware,
            apiStatisticsSlice.middleware,
            apiOurVisionSlice.middleware
        ),
    devTools: process.env.NODE_ENV === "development",
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
