const getFromStorage = (key: string): any => {
    let field: any = "";
    if (typeof window !== "undefined") {
        const storedValue = localStorage.getItem(key);
        if (typeof storedValue === "string") {
            try {
                field = JSON.parse(storedValue);
            } catch (error) {}
        }
    }

    return field;
};

export default getFromStorage;
