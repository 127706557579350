"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    EditAllSubcategoryPayload,
    EditSubcategoryPayload,
    EditSubcategoryState,
} from "@/redux/types/edits/EditSubcategoryTypes";

const initialState: EditSubcategoryState = {};

const editSubcategorySlice = createSlice({
    name: "editSubcategorySlice",
    initialState,
    reducers: {
        updateSubcategoryAllFields: (
            state,
            action: PayloadAction<EditAllSubcategoryPayload>
        ) => {
            const { id, value } = action.payload;
            state[`${id}`] = value;
        },
        updateSubcategoryOneField: (
            state,
            action: PayloadAction<EditSubcategoryPayload>
        ) => {
            const { id, key, value } = action.payload;
            state[`${id}`][`${key}`] = value;
        },
    },
});

const EditSubcategoryReducer = editSubcategorySlice.reducer;

export const { updateSubcategoryAllFields, updateSubcategoryOneField } =
    editSubcategorySlice.actions;
export default EditSubcategoryReducer;
