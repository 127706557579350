import { apiAuthSlice } from "@/redux/app/api/apiSlice";

export const authApiSlice = apiAuthSlice.injectEndpoints({
    endpoints: (builder) => ({
        resetPassword: builder.mutation({
            query: (values) => ({
                url: "/api/v1/auth/reset-password",
                method: "PUT",
                body: { ...values },
            }),
        }),
        logoutApi: builder.mutation({
            query: () => ({
                url: "/api/v1/auth/logout",
                method: "POST",
            }),
        }),
    }),
});

export const {
    useResetPasswordMutation,
    useLogoutApiMutation,
} = authApiSlice;
