"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getFromStorage from "@/utils/localStorage/getFromStorage";
import setToLocalStorage from "@/utils/localStorage/setToLocalStorage";
import Cookies from "js-cookie";
import { AuthPayload, AuthState } from "@/redux/types/auth/AuthTypes";

const initialState: AuthState = {
    email: getFromStorage("email") || "",
    accessToken: getFromStorage("accessToken") || null,
    user: getFromStorage("user") || null,
};

const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<AuthPayload>) => {
            const { email, accessToken, user } = action.payload;
            state.email = email;
            state.user = user;
            state.accessToken = accessToken;
            setToLocalStorage(action.payload);
            accessToken && Cookies.set("loggedIn", "true", { expires: 90 });
            user && Cookies.set("role", user?.role, { expires: 90 });
            accessToken && Cookies.remove("register");
            accessToken && Cookies.remove("forget");
        },
        updateUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
            setToLocalStorage(action.payload);
        },
        updateToken: (state, action: PayloadAction<any>) => {
            state.accessToken = action.payload;
            setToLocalStorage(action.payload);
            Cookies.set("loggedIn", "true", { expires: 90 });
            Cookies.remove("register");
            Cookies.remove("forget");
        },
        logout: (state) => {
            state.email = null;
            state.user = null;
            state.accessToken = null;
            window?.localStorage.removeItem("user");
            window?.localStorage.removeItem("accessToken");
            //cookies for protect paths
            Cookies.remove("loggedIn");
            Cookies.remove("role");
            Cookies.remove("register");
            Cookies.remove("forget");
            window.location.href = "/login";
        },
    },
});

const authReducer = authSlice.reducer;

export const { setCredentials, logout, updateUser, updateToken } =
    authSlice.actions;

export default authReducer;
