"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    EditAllCategoryPayload,
    EditCategoryPayload,
    EditCategoryState,
} from "@/redux/types/edits/EditCategoryTypes";

const initialState: EditCategoryState = {};

const editCategorySlice = createSlice({
    name: "editCategorySlice",
    initialState,
    reducers: {
        updateCategoryAllFields: (
            state,
            action: PayloadAction<EditAllCategoryPayload>
        ) => {
            const { id, value } = action.payload;
            state[`${id}`] = value;
        },
        updateCategoryOneField: (
            state,
            action: PayloadAction<EditCategoryPayload>
        ) => {
            const { id, key, value } = action.payload;
            state[`${id}`][`${key}`] = value;
        },
    },
});

const EditCategoryReducer = editCategorySlice.reducer;

export const { updateCategoryAllFields, updateCategoryOneField } =
    editCategorySlice.actions;
export default EditCategoryReducer;
