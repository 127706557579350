"use client"
import { setSearch } from "@/redux/features/local/search/searchSlice";
import { useState } from "react";
import * as Yup from "yup";
import { useAppDispatch } from "@/redux/app/hooks";
import { useRouter } from "next/navigation";
import setToLocalStorage from "@/utils/localStorage/setToLocalStorage";

type FormSearchValues = {
    searchValue: ""
}
const useSearch = (keySearch: string | undefined, redirect: string | undefined, defaultValue?: any) => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [errServerMsg, setErrServerMsg] = useState<string | []>("");
    const initialValues: FormSearchValues = {
        searchValue: defaultValue || "",
    };

    const validationSchema = Yup.object({
        searchValue: Yup.string().required("اكتب عنوان ما تريد أن تبحث عنه"),
    });
    const handleChange = (currentValue: string) => {
        dispatch(setSearch({ key: keySearch, value: currentValue }));
        setToLocalStorage({ [`${keySearch}`]: currentValue });
    }

    const handleSubmit = async (values: FormSearchValues, submitActions: any) => {
        dispatch(setSearch({ key: keySearch, value: values.searchValue }));
        setToLocalStorage({ [`${keySearch}`]: values.searchValue });
        redirect && router.push(redirect);
        setErrServerMsg("");
        submitActions.setSubmitting(false);
    };

    return {
        initialValues,
        validationSchema,
        handleSubmit,
        handleChange,
        errServerMsg,
    };
}

export default useSearch;