import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    coursePublish: false,
    chapterPublish: false,
};

const editPublishSlice = createSlice({
    name: "editPublishSlice",
    initialState,
    reducers: {
        updateCoursePublish: (state, action: PayloadAction<boolean>) => {
            state.coursePublish = action.payload;
        },
        updateChapterPublish: (state, action: PayloadAction<boolean>) => {
            state.chapterPublish = action.payload;
        },
    },
});

const EditUpdatePublish = editPublishSlice.reducer;

export const { updateCoursePublish, updateChapterPublish } =
    editPublishSlice.actions;
export default EditUpdatePublish;
