"use client";
import { useRouter, usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { logout } from "@/redux/features/local/auth/authSlice";
import { RootState } from "@/redux/app/store";
import { useAppDispatch, useAppSelector } from "@/redux/app/hooks";
import { linkMenu, userData } from "@/types/hooksTypes";
import getFromStorage from "@/utils/localStorage/getFromStorage";
import roles from "@/utils/auth/roles/roles";
import { useLogoutApiMutation } from "@/redux/features/api/auth/authApiSlice";
import toast from "react-hot-toast";

function useHeader() {
    const [logoutApi, { isLoading }] = useLogoutApiMutation();

    const dispatch = useAppDispatch();
    const pathName = usePathname();
    const router = useRouter();

    const [links, setLinks] = useState<linkMenu>([]);
    const [linksActive, setLinksActive] = useState("");
    const [userActive, setUserActive] = useState("");

    const userFromStore = useAppSelector((store: RootState) => store.auth.user);
    const accessTokenFromStore = useAppSelector((store: RootState) => store.auth.accessToken);

    const [user, setUser] = useState<userData>();
    const [accessToken, setAccessToken] = useState<string>();
    const [pageLoaded, setpageLoaded] = useState<boolean>(false);
    useEffect(() => {
        setpageLoaded(true);
    }, []);

    useEffect(() => {
        const userData = getFromStorage('user') as userData;
        const accessTokenData = getFromStorage('accessToken');

        setUser(userData);
        setAccessToken(accessTokenData);

        if (userData?.role === roles.STUDENT) {
            setLinks([
                { id: "link-1", path: "/", name: "الرئيسية", hide: false },
                { id: "link-2", path: "/courses", name: "الدورات", hide: false },
                { id: "link-3", path: "/user/my-courses", name: "دوراتي", hide: true },
                { id: "link-4", path: "/about", name: "رؤيتنا", hide: false },
            ])
        } else {
            setLinks([
                { id: "link-1", path: "/", name: "الرئيسية", hide: false },
                { id: "link-2", path: "/courses", name: "الدورات", hide: false },
                { id: "link-4", path: "/about", name: "رؤيتنا", hide: false },
            ])
        }
    }, [userFromStore, accessTokenFromStore])

    const handleClickMenu = () => {
        linksActive === "active"
            ? setLinksActive("")
            : setLinksActive("active");
        setUserActive("");
    };
    const handleUserMenu = () => {
        userActive === "active" ? setUserActive("") : setUserActive("active");
        setLinksActive("");
    };
    const handleClickLogo = () => {
        router.push(links[0].path);
    };
    const handleClickDisAppearMenu = () => {
        setLinksActive("");
    };
    const handleClickDisUserMenu = () => {
        setUserActive("");
    };
    const handleLogout = async () => {
        try {
            const resData = await logoutApi({}).unwrap()
            dispatch(logout());
            setLinks([{ id: "link-1", path: "/", name: "الرئيسية", hide: false },
            { id: "link-2", path: "/courses", name: "الدورات", hide: false },
            { id: "link-4", path: "/about", name: "رؤيتنا", hide: false },]);
            setUser({});
            setAccessToken("");
            setUserActive("");
        } catch (err) {
            toast.error("فشلت عملية تسجيل الخروج من الموقع")
        }
    };
    return {
        linksActive,
        setLinksActive,
        userActive,
        links,
        setLinks,
        pathName,
        router,
        handleClickMenu,
        handleUserMenu,
        handleClickLogo,
        handleClickDisAppearMenu,
        userLoggedName: user?.firstName,
        userLoggedImage: user?.profileImageUrl,
        role: user?.role?.toLowerCase(),
        accessToken,
        handleLogout,
        handleClickDisUserMenu,
        pageLoaded
    };
}

export default useHeader;
